#root>div {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.soon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.soon {
    font-weight: 800;
    letter-spacing: .1rem;
    font-size: 5rem;
}

.signature {
    font-weight: 100;
    padding-left: 15rem;
}

.signature a {
    color: #fff;
    text-decoration: none;
}